import gql from 'graphql-tag'

import {
  UrlFragment,
  ArticleCardFragment,
  SimpleProductCardFragment,
} from '../fragments'

export const fetchShopMenuItems = gql`
  query {
    allShop_categorys(sortBy: menu_order_DESC) {
      edges {
        node {
          _meta {
            id
            uid
            type
          }
          title
          feature_title
          feature_image
          feature_text
          feature_link {
            ...UrlFragment
          }
          feature_link_text
          highlights {
            collection {
              ... on Collection {
                title
                _meta {
                  uid
                  id
                  type
                }
              }
              ... on Product {
                title
                _meta {
                  uid
                  id
                  type
                }
              }
              ... on Custom_landing {
                title
                _meta {
                  uid
                  id
                  type
                }
              }
            }
            is_red
          }
          featured_brands {
            item {
              ... on Brand {
                title
                _meta {
                  uid
                  id
                  type
                }
              }
            }
          }
          featured_concerns {
            item {
              ... on Concern {
                title
                _meta {
                  uid
                  id
                  type
                }
              }
            }
          }
          featured_articles {
            item {
              ... on Article {
                title
                _meta {
                  uid
                  id
                  type
                }
              }
            }
          }
          show_articles_not_concerns
        }
      }
    }
    allProduct_types(sortBy: menu_order_DESC) {
      edges {
        node {
          title
          featured_image
          shopCategory {
            ... on Shop_category {
              title
              _meta {
                id
                uid
                type
              }
            }
          }
          featured_products {
            ... on Product_typeFeatured_products {
              product {
                ...SimpleProductCardFragment
              }
            }
          }
          featured_collections {
            ... on Product_typeFeatured_collections {
              collection {
                ... on Collection {
                  title
                  _meta {
                    id
                    uid
                    type
                  }
                }
              }
            }
          }
          show_featured_collections_menu
          menu_order
          _meta {
            id
            uid
            type
          }
        }
      }
    }
  }
  ${SimpleProductCardFragment}
  ${UrlFragment}
`

export const fetchAllShopCategories = gql`
  query {
    allShop_categorys(sortBy: menu_order_DESC) {
      edges {
        node {
          _meta {
            id
            uid
            type
          }
          title
          featured_product {
            ...SimpleProductCardFragment
          }
          featured_product_image
          featured_product_text
          featured_product_link_text
          featured_brands {
            item {
              ... on Brand {
                title
                _meta {
                  uid
                  id
                  type
                }
              }
            }
          }
          featured_concerns {
            item {
              ... on Concern {
                title
                _meta {
                  uid
                  id
                  type
                }
              }
            }
          }
          highlights {
            collection {
              ... on Collection {
                title
                _meta {
                  uid
                  id
                  type
                }
              }
            }
          }
        }
      }
    }
  }
  ${SimpleProductCardFragment}
`

export const fetchAllProductTypes = gql`
  query ($cursor: String!) {
    allProduct_types(after: $cursor, sortBy: menu_order_DESC) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          title
          featured_image
          shopCategory {
            ... on Shop_category {
              title
              _meta {
                id
                uid
                type
              }
            }
          }
          featured_products {
            ... on Product_typeFeatured_products {
              product {
                ...SimpleProductCardFragment
              }
            }
          }
          featured_collections {
            ... on Product_typeFeatured_collections {
              collection {
                ... on Collection {
                  title
                  _meta {
                    id
                    uid
                    type
                  }
                }
              }
            }
          }
          show_featured_collections_menu
          menu_order
          hide_from_locale
          _meta {
            id
            uid
            type
          }
        }
      }
    }
  }
  ${SimpleProductCardFragment}
`

export const fetchShopCategoryProductTypes = gql`
  query ($input: String!) {
    allProduct_types(where: { shopCategory: $input }) {
      edges {
        node {
          title
          _meta {
            id
            uid
            type
          }
        }
      }
    }
  }
`

export const fetchGlobalDataQuery = gql`
  query {
    allGlobals {
      edges {
        node {
          site_tagline
          cookie_bar_text
          show_header_alert
          header_alert_left_text
          header_alert_left_text_us
          header_alert_right_text
          quiz_icon_callout_text
          questions_icon_callout_text
          makeup_alert_icon_callout_text
          shop_icon_callout_link {
            ...UrlFragment
          }
          primary_footer_menu_heading
          primary_footer_menu {
            ... on Menu {
              title
              links {
                text
                url {
                  ...UrlFragment
                }
              }
            }
          }
          secondary_footer_menu_heading
          secondary_footer_menu {
            ... on Menu {
              title
              links {
                text
                url {
                  ...UrlFragment
                }
              }
            }
          }
          social_footer_menu_heading
          social_menu {
            ... on Menu {
              title
              links {
                text
                url {
                  ...UrlFragment
                }
              }
            }
          }
          legal_menu {
            ... on Menu {
              title
              links {
                text
                url {
                  ...UrlFragment
                }
              }
            }
          }
          newsletter_heading
          newsletter_description
          instagram_handle
          instagram_heading
          instagram_description
          instagram_button_text
          instagram_additional_image
          article_gate_title
          article_gate_content
          meta_title
          meta_description
          meta_keywords
          meta_image
        }
      }
    }
  }
  ${UrlFragment}
`

export const fetchAdditionalGlobalDataQuery = gql`
  query {
    allGlobals {
      edges {
        node {
          cart_menu {
            ... on Menu {
              links {
                text
                url {
                  ...UrlFragment
                }
              }
            }
          }
          cart_callout
          show_cart_discount_note
          cart_register_text
          cart_register_cta_text
          enable_gift_wrap
          enable_tiered_discount_ca
          enable_tiered_discount_us
          gift_wrap_product_ca
          gift_wrap_product_us
          search_menu {
            ... on Menu {
              links {
                text
                url {
                  ...UrlFragment
                }
              }
            }
          }
          search_trending_product {
            ...SimpleProductCardFragment
          }
          search_callout
          header_about_image
          header_about_description
          header_about_menu {
            ... on Menu {
              links {
                text
                url {
                  ...UrlFragment
                }
              }
            }
          }
          header_studio_image
          header_studio_heading
          header_studio_description
          header_blog_description
        }
      }
    }
    allArticles(
      first: 2
      sortBy: published_date_DESC
      where: { hide_article: false }
    ) {
      edges {
        node {
          ...ArticleCardFragment
        }
      }
    }
    allBlog_landings {
      edges {
        node {
          featured_categories {
            category {
              ... on Blog_category {
                title
                _meta {
                  uid
                  type
                }
              }
            }
          }
        }
      }
    }
    allBlog_categorys(first: 8, sortBy: meta_firstPublicationDate_DESC) {
      edges {
        node {
          title
          _meta {
            uid
          }
        }
      }
    }
    allStudios {
      edges {
        node {
          title
          _meta {
            uid
          }
          country
          description
          featured_image
          address_line_1
          phone_number
          text_phone_number
          email
          services {
            service_category {
              ... on Service_category {
                title
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
  ${UrlFragment}
  ${SimpleProductCardFragment}
  ${ArticleCardFragment}
`

export const fetchSearchResultsByTerm = gql`
  query ($term: String!, $cursor: String!) {
    _allDocuments(fulltext: $term, after: $cursor) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          _meta {
            uid
            id
            type
          }
          ... on Product {
            ...SimpleProductCardFragment
          }
          ... on Product_type {
            title
            shopCategory {
              ... on Shop_category {
                title
                _meta {
                  id
                  uid
                  type
                }
              }
            }
          }
          ... on Shop_category {
            title
          }
          ... on Collection {
            title
          }
          ... on Concern {
            title
          }
          ... on Custom_landing {
            title
          }
          ... on Brand {
            title
          }
          ... on Page {
            title
            featured_image
          }
          ... on Article {
            ...ArticleCardFragment
          }
          ... on Studio {
            title
            featured_image
          }
          ... on Service_category {
            title
            featured_image
          }
          ... on About {
            title
          }
        }
      }
    }
  }
  ${SimpleProductCardFragment}
  ${ArticleCardFragment}
`

export const fetchDocumentsForAlgolia = gql`
  query ($cursor: String!) {
    _allDocuments(after: $cursor) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          _meta {
            uid
            id
            type
          }
          ... on Product {
            ...SimpleProductCardFragment
            description
            details
          }
          ... on Product_type {
            title
            shopCategory {
              ... on Shop_category {
                title
                _meta {
                  id
                  uid
                  type
                }
              }
            }
          }
          ... on Shop_category {
            title
          }
          ... on Collection {
            title
          }
          ... on Concern {
            title
          }
          ... on Custom_landing {
            title
          }
          ... on Blog_category {
            title
          }
          ... on Brand {
            title
          }
          ... on Page {
            title
            featured_image
          }
          ... on Article {
            title
            content
            featured_image
            body {
              ... on ArticleBodySide_widget {
                fields {
                  product {
                    ... on Product {
                      title
                      brand {
                        ... on Brand {
                          title
                          _meta {
                            uid
                            id
                            type
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on ArticleBodyProduct_widget {
                fields {
                  product {
                    ... on Product {
                      title
                      brand {
                        ... on Brand {
                          title
                          _meta {
                            uid
                            id
                            type
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on Studio {
            title
            featured_image
          }
          ... on Service_category {
            title
            featured_image
          }
          ... on About {
            title
          }
        }
      }
    }
  }
  ${SimpleProductCardFragment}
`

export const fetchDocumentsByIDForAlgolia = gql`
  query ($ids: [String!]!, $cursor: String!) {
    _allDocuments(id_in: $ids, after: $cursor) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          _meta {
            uid
            id
            type
          }
          ... on Product {
            ...SimpleProductCardFragment
            description
            details
          }
          ... on Product_type {
            title
            shopCategory {
              ... on Shop_category {
                title
                _meta {
                  id
                  uid
                  type
                }
              }
            }
          }
          ... on Shop_category {
            title
          }
          ... on Collection {
            title
          }
          ... on Concern {
            title
          }
          ... on Custom_landing {
            title
          }
          ... on Blog_category {
            title
          }
          ... on Brand {
            title
          }
          ... on Page {
            title
            featured_image
          }
          ... on Article {
            title
            content
            featured_image
            body {
              ... on ArticleBodySide_widget {
                fields {
                  product {
                    ... on Product {
                      title
                      brand {
                        ... on Brand {
                          title
                          _meta {
                            uid
                            id
                            type
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on ArticleBodyProduct_widget {
                fields {
                  product {
                    ... on Product {
                      title
                      brand {
                        ... on Brand {
                          title
                          _meta {
                            uid
                            id
                            type
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on Studio {
            title
            featured_image
          }
          ... on Service_category {
            title
            featured_image
          }
          ... on About {
            title
          }
        }
      }
    }
  }
  ${SimpleProductCardFragment}
`
